import { Link } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { listNav } from "../../PageData/footerInfo";
import { GridLayout, ScrollTop, TypographyCustom } from "../../components";
import { commonStyle } from "../../style";

const Footer = (props) => {
  const location = useLocation();
  const nagivate = useNavigate();
  const { setStickyMode } = props.pageFunc;
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  const handleOnclick = (routePath) => {
    if (location.pathname !== routePath) nagivate(routePath);
    setStickyMode(false);
    const element = document.getElementById("top-page");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <div>
      {!isMobile && <div style={commonStyle.common.spliterLine} />}
      <GridLayout
        md={[2, 4, 4, 2]}
        xs={[12]}
        style={{
          height: isMobile ? "fit-content" : commonStyle.footer.height - 20,
          borderTop: isMobile ? "1px solid grey" : undefined,
          padding: isMobile ? "8px" : undefined,
        }}
      >
        <></>
        <>
          <TypographyCustom
            style={{
              ...commonStyle.footer.title,
              fontSize: isMobile ? 13 : 20,
              //marginTo,
            }}
          >
            {listNav.companyInfo.companyName}
          </TypographyCustom>
          {listNav.companyInfo.otherInfo.map((info, index) => {
            return (
              <TypographyCustom
                key={index}
                style={{
                  ...commonStyle.footer.item,
                  fontSize: isMobile ? 13 : 18,
                }}
              >
                {info}
              </TypographyCustom>
            );
          })}
        </>
        <>
          <TypographyCustom
            style={{
              ...commonStyle.footer.title,
              fontSize: isMobile ? 13 : 20,
            }}
          >
            {listNav.siteMap.title}
          </TypographyCustom>
          {listNav.siteMap.listSizeMap.map((site, index) => {
            return (
              <Link
                component="button"
                style={{ width: "100%", textAlign: "left" }}
                onClick={() => {
                  handleOnclick(site.url);
                }}
                key={index}
              >
                <TypographyCustom
                  key={index}
                  style={{
                    ...commonStyle.footer.itemLink,
                    fontSize: isMobile ? 13 : 18,
                  }}
                >
                  {site.linkName}
                </TypographyCustom>
              </Link>
            );
          })}
        </>
        <></>
      </GridLayout>
      {!isMobile && <div style={commonStyle.common.spliterLine} />}
      <ScrollTop />
    </div>
  );
};

export default Footer;
