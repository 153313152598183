import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "./style.css";

const SlideShow = (props) => {
  const [slideIndex, setSlideIndex] = useState(1);
    const { ImageList } = props;
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    useEffect(() => {
      async function makeRequest() {
        await delay(4000);

        if (slideIndex >= 2) {
          setSlideIndex(0);
        } else setSlideIndex(slideIndex + 1);
      }

      makeRequest();
    }, [slideIndex]);

    useEffect(() => {
      setSlideIndex(slideIndex + 1);
    }, []);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 400,
        }}
      >
        {ImageList.map((image, index) => {
          return (
            <>
              <Box
                key={index}
                style={{
                  overflow: "hidden",
                  width: slideIndex === index ? "760px" : "0px",
                  transition: "width 1.5s",
                  border: "2px  solid #9DF05E",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={image}
                    alt=""
                    style={{
                      height: 380,
                      width: "auto",
                      marginTop: "8px",
                      border: "1px solid #9DF05E",
                    }}
                  />
                </div>
              </Box>
            </>
          );
        })}
      </div>
    );
};

export default SlideShow;
