import { pageInfoRecruit } from "../../PageData/Recruit/recruitPage";
import { PageBodyContainer, TypographyCustom } from "../../components";
const Recruit = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoRecruit}>
      <TypographyCustom fontSize={"large"} fontWeight={"bold"} mt={2} mb={4}>
        詳細についてはメールでお問い合わせください。
        <br />
        担当者：管理者 上田美香
      </TypographyCustom>
    </PageBodyContainer>
  );
};

export default Recruit;
