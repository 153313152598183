import { routePath } from "../../routes/routePath";

export const pageInfoOperating = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "運営規定", url: routePath.operating },
  ],
  pageHeader: "運営規定",
};

export const topicOperating = {
  header: "運営規定",
  content: [
    "第１条 　株式会社ピースが開設する訪問看護ステーション　グリーンピース（以下「事業所」という。）が行う指定訪問看護及び指定介護予防訪問看護事業の事業（以下「事業」という。）の適正な運営を確保するために人員及び管理運営に関する事項を定め、事業所の看護師その他の従業者が（以下「看護師等」という。）が、居宅事業にあっては要介護状態にある、また予防事業にあっては要支援状態にある高齢者に対し、適正な指定訪問看護又は指定介護予防訪問看護（以下「訪問看護等」という）を提供することを目的とする。",
    "",
    "　（指定訪問看護事業の運営の方針）",
    "第２条　事業所の看護師等は　要介護者の心身の特性を踏まえて、可能な限りその居宅において、その有する能力に応じ自立した日常生活を営むことができるよう、その療養生活を支援することにより、利用者の心身機能の維持回復を図り、もって利用者の生活機能の維持又は向上を目指す。",
    "２　事業の実施に当たっては、地域との結びつきを重視し、市町村、居宅介護支援事業所、包括支援センター、老人介護支援センター、他の居宅サービス事業者その他の保健・医療・福祉サービスを提供する者、住民による自発的な活動によるサービスを含めた地域における様々な取り組みを行う者等と密接な連携を図り、総合的なサービスの提供に努める。",
    "",
    "（指定介護予防訪問看護事業の運営の方針）",
    "第３条　事業所の看護師等は、要支援者の心身の特性を踏まえて、可能な限りその居宅において、要支援状態の維持若しくは改善を図り、又は要介護状態となることを予防し、自立した日常生活を営むことができるよう、その療養生活を支援することにより、利用者の心身機能の維持回復を図り、もって利用者の生活機能の維持又は向上を目指す。",
    "２　事業の実施に当たっては、地域との結びつきを重視し、市町村、地域包括支援センター、老人介護支援センター、他の介護予防サービス事業者その他の保健・医療・福祉サービスを提供する者、住民による自発的な活動によるサービスを含めた地域における様々な取り組みを行う者等と密接な連携を図り、総合的なサービスの提供に努める。",
    "",
    "　（事業所の名称等）",
    "第４条　事業を行う事業所の名称及び所在地は、次のとおりとする。",
    "　一　名称　　訪問看護ステーション　グリーンピース",
    "　二　所在地　　広島市南区的場町2丁目6-3JH的場301号室",
    "",
    "　（従業員の職種、員数及び職務内容）",
    "第５条　事業所に勤務する従業員の職種、員数及び職務内容は次のとおりとする。",
    "　一　管理者　１名（看護職員と兼務）",
    "　　　管理者は、事業所の従業者の管理及び訪問看護等の利用の申込に係る調整、業務の実施状況の把握その他の管理を一元的に行うとともに、自らも訪問看護等の提供に当たるものとする。",
    "　二　看護職員　　看護師3名以上は、訪問看護計画書及び介護予防訪問看護計画書",
    "　　（以下「訪問看護計画書等」という）、又は訪問看護報告書及び介護予防訪問看護報告書（以下「訪問看護報告書等」という。）を作成し、利用者又はその家族に説明する。",
    "　　　看護職員は、訪問看護等の提供に当たる。",
    "",
    "　　（営業日及び営業時間）",
    "第６条　事業所の営業日及び営業時間は、次のとおりとする。",
    "　一　営業日　月曜日から金曜日までとする。ただし、８月１３日から１５日、１２月３",
    "０日から１月３日までを除く。",
    "　二　営業時間　午前９時から午後６時までとする。",
    "　三　電話等により、２４時間常時連絡が可能な体制とする。",
    "",
    "　（訪問看護等の内容及）",
    "第７条　訪問看護等の内容は次のとおりとする。",
    "　一　病状・障害の観察",
    "　二　褥創の予防・処置",
    "　三　リハビリテーション",
    "　四　ターミナルケア",
    "　五　認知症患者の看護",
    "　六　療養生活や介護方法の指導",
    "　七　カテーテル等の管理",
    "　八　その他医師の指示による医療処置",
    "",
    "　（利用料等）",
    "第８条　訪問看護等を提供した場合の利用料の額は、厚生労働大臣が定める基準によるものとし、当該訪問看護等が法定代理受領サービスであるときには、介護報酬告示上の額に各利用者の介護保険負担割合証に記載された負担割合を乗じた額とする。",
    "２　次条に規定する通常の事業の実施地域を越えて行う訪問看護等に要した交通費は、実費とする。ただし、車を使用して訪問した場合は実施地域を越えた地点から、１キロ以上は55円とする。",
    "３　死後の処置料は、25,000円とする。",
    "４　前２項の費用の支払いを受ける場合には、利用者又はその家族に対して事前に文書で　説明した上で､支払いに同意する旨の文書に署名(記名押印)を受けることとする。",
    "",
    "　（通常の事業の実施地域）",
    "第９条　通常の事業の実施地域は、広島市中区、南区、東区、西区、安佐南区、安佐北区佐伯区の区域とする。",
    "",
    "　（緊急時等における対応方法）",
    "第１０条　看護師等は、訪問看護等を実施中に、利用者の病状に急変、その他緊急事態が生じたときは、速やかに主治医に連絡し、適切な処置を行うこととする。",
    "２　看護師等は、前項について、しかるべき処置をした場合は、速やかに管理者及び主治　医に報告しなければならない。",
    "",
    "（虐待防止に関する事項）",
    "第１１条　事業所は、利用者の人権の擁護・虐待の防止等のため次の措置を講ずるものとする。",
    "一　虐待の発生またはその再発を防止するため、以下の措置を講じる",
    "l  虐待を防止するための対策を検討する委員会（テレビ電話装置等の活用可能）を定期的に開催するとともに、その結果について、従事者に周知徹底を図る事。",
    "l  虐待防止のための指針を整備すること",
    "l  従業者に対し、虐待防止のための研修を定期的に実施すること",
    "l  上記外を適切に実施するための担当者を置くこと",
    "",
    "二　利用者及びその家族からの苦情処理体制の整備",
    "三　その他虐待防止のために必要な措置",
    "",
    "２　事業所は、サービス提供中に、当該事業所従事者又は養護者（利用者の家族等高齢者を現に養護する者）による虐待を受けたと思われる利用者を発見した場合は、速やかに、これを市町村に通報するものとする。",
    "",
    "　（その他運営についての留意事項）",
    "第１２条　事業所は、看護師等の質的向上を図るための研修の機会を次のとおり設けるものとし、また、業務体制を整備する。",
    "　一　採用時研修　採用後３ヶ月以内",
    "　二　継続研修　年２回",
    "２　従業者は業務上知り得た利用者又はその家族の秘密を保持する。",
    "３　従業者であった者に、業務上知り得た利用者又はその家族の秘密を保持させるため、　従業者でなくなった後においてもこれらの秘密を保持するべき旨を、従業者との雇用契　約の内容とする。",
    "４　事業所は、利用者に対する訪問看護等の提供に関する諸記録を整備し、その完結の日から５年間保管しなければならない。",
    "５　この規程に定める事項の外、運営に関する重要事項は、株式会社ピースと事業所の管理者との協議に基づいて定めるものとする。",
    "",
    "　　附　則",
    "　この規定は、令和2年6月1から施行する。",
    "　この規程は、令和2年11月12日から施行する。",
    "　この規程は、令和3年12月１日から施行する。",
    "　この規程は、令和4年2月1日から施行する。",
    "　この規程は、令和5年9月18日から施行する。",
  ],
};
