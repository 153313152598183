import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Link } from "@mui/material";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { companyInfo, listMenu } from "../../PageData/headerInfo";
import { GridLayout, TypographyCustom } from "../../components";
import { routePath } from "../../routes/routePath";
import { commonStyle } from "../../style";

const HeaderMobile = (props) => {
  const { setStickyMode } = props.pageFunc;
  const { stickyMode, currentPage } = props.pageState;
  const location = useLocation();
  const nagivate = useNavigate();

  const [displaySubmenu, setDisplaySubMenu] = useState(false);

  const style = {
    header: {
      position: "fixed",
      top: 0,
      backgroundColor: "#FDFEE2",
      borderBottom: "1px solid grey",
      height: "70",
      padding: "8px",
      width: "100%",
      zIndex: 3,
    },
    listMenu: {
      position: "fixed",
      top: displaySubmenu ? 65 : -500,
      width: "100vw",
      transition: "1s",
      backgroundColor: "#FDFEE2",
      borderBottom: "1px solid grey",
      zIndex: 2,
    },
    headerMenu: {
      height: "fit-content",
      textAlign: "Left",
      padding: 4,
    },
    companyName: {
      name1: {
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "13px",
      },
      name2: {
        color: "#2e7d32",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "18px",
      },
    },
    contact: {
      contact1: {
        color: "#0A5027",
        paddingTop: "0px",
        textAlign: "left",
        //fontWeight: "bold",
        fontSize: "11px",
      },
      contact2: {
        color: "#0A5027",
        fontWeight: "bold",
        margin: "0px 16px 0px 16px",
        textAlign: "center",
        fontSize: stickyMode ? "18px" : "22px",
        transition: "0.4s",
        borderRadius: "5px",
      },
    },
  };

  const handleOnclick = (index, routePath) => {
    if (location.pathname !== routePath) nagivate(routePath);
    setStickyMode(false);
    setDisplaySubMenu(false);
    const element = document.getElementById("top-page");
    element.scrollIntoView({ block: "center" });
  };

  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  const sendMail = () => {
    window.open(
      "mailto:greenpeace0612@gmail.com?subject=件名をご記入下さい&body=本文をご記入下さい"
    );
  };

  return (
    <>
      <div id="header" style={style.header}>
        <GridLayout xs={[5, 6, 1]}>
          <Link
            component="button"
            onClick={() => {
              handleOnclick(-1, routePath.homepage);
            }}
            underline="none"
          >
            <TypographyCustom style={style.companyName.name1}>
              {companyInfo.conpanyName.name1}
            </TypographyCustom>
            <TypographyCustom style={style.companyName.name2}>
              {companyInfo.conpanyName.name2}
            </TypographyCustom>
          </Link>
          <Link
            component="button"
            onClick={() => {
              //handleOnclick(-1, routePath.contact);
            }}
            underline="none"
          >
            <TypographyCustom style={style.contact.contact1}>
              〒732-0824
              <br />
              広島県広島市南区的場町2丁目6-3
              <br />
              電話番号　082-909-2920{" "}
              <IconButton
                size="large"
                edge="start"
                color="success"
                aria-label="menu"
                //sx={{ mr: 2 }}
                style={{ margin: 0, marginLeft: 16, padding: 0 }}
                onClick={() => {
                  sendMail();
                }}
              >
                <EmailIcon />
              </IconButton>
            </TypographyCustom>
          </Link>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              setDisplaySubMenu(!displaySubmenu);
            }}
          >
            <MenuIcon />
          </IconButton>
        </GridLayout>
      </div>

      <GridLayout xs={[12]} style={style.listMenu}>
        {listMenu?.map((menu, index) => (
          <Link
            key={index}
            component="button"
            onClick={() => {
              handleOnclick(index, menu.routePath);
            }}
            underline="none"
            width={"100%"}
            height={"fit-content"}
          >
            <GridLayout
              xs={[12]}
              style={{
                ...style.headerMenu,
                background:
                  index === currentPage
                    ? "linear-gradient(to right, #B3DA65, #FDFEE2)"
                    : undefined,
              }}
            >
              <div>
                <img
                  src={`./MenuIcon/${menu.image}.png`}
                  alt=""
                  width={"36px"}
                  style={{ marginLeft: "8px", display: "inline-block" }}
                ></img>

                <TypographyCustom
                  style={{
                    ...commonStyle.header.menuText,
                    fontSize: "15px",
                    fontWeight: "normal",
                    display: "inline-block",
                    marginLeft: "8px",
                  }}
                >
                  {menu.text}
                </TypographyCustom>
              </div>
            </GridLayout>
          </Link>
        ))}
      </GridLayout>
    </>
  );
};

export default HeaderMobile;
