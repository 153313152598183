import { Newspaper } from "@mui/icons-material";
import {
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  SlideShowImage,
  homePageButtonsList,
  newList,
  tabList,
} from "../../PageData/Hompage/homePage";
import {
  ButtonCustom,
  GridLayout,
  PageBodyContainer,
  SlideShow,
  Tabs,
  TypographyCustom,
} from "../../components";
import { commonStyle } from "../../style";

const HomePage = () => {
  const [setTabIndex] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  const nagivate = useNavigate();

  return (
    <GridLayout
      md={[12]}
      xs={[12]}
      style={{
        marginTop: isMobile ? 65 : 300,
        overflow: "display",
        display: "flex",
      }}
    >
      <div
        style={{
          ...commonStyle.common.centerMiddleBox,
        }}
      >
        <Link
          underline="none"
          href=""
          style={{
            border: "3px solid lightgreen",
            borderRadius: "5px",
            padding: "8px 32px 8px 32px",
            fontSize: isMobile ? "12px" : "35px",
            width: "fit-content",
            fontWeight: "bold",
            color: "black",
            marginBottom: 16,
            marginTop: 16,
          }}
        >
          {"私たちは豊かな高齢社会の創造に貢献します"}
        </Link>
      </div>

      <SlideShow ImageList={SlideShowImage}></SlideShow>

      <PageBodyContainer style={{ marginTop: 32 }}>
        <GridLayout md={[6]} xs={[6]} spacing={4}>
          {homePageButtonsList.map((button, index) => {
            return (
              <ButtonCustom
                key={index}
                style={{
                  width: "100%",
                  height: "fit-content",
                  minHeight: "250px",
                  backgroundColor: button.bgcolor,
                  ...commonStyle.common.centerMiddleBox,
                }}
                disabled={button.disabled}
                onClick={() => nagivate(button.url)}
              >
                <img src={`./MenuIcon/${button.icon}.png`} alt=""></img>
                <TypographyCustom
                  style={{
                    ...commonStyle.header.menuText,
                    fontSize: isMobile ? "13px" : "25px",
                  }}
                >
                  {button.text}
                </TypographyCustom>
              </ButtonCustom>
            );
          })}
        </GridLayout>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 48,
            marginBottom: 16,
          }}
        >
          <Newspaper
            color="warning"
            style={{ fontSize: isMobile ? 30 : 60 }}
          ></Newspaper>
          <TypographyCustom
            color="#ed6c02"
            style={{ paddingLeft: 4, fontSize: isMobile ? 23 : 43 }}
          >
            お知らせ
          </TypographyCustom>
        </Box>

        <Tabs tabList={tabList} setSelectTabIndex={setTabIndex}></Tabs>
        <TableContainer component={Paper} style={{ marginBottom: "32px" }}>
          <Table style={{ width: "max-content", overflow: "scroll" }}>
            <TableBody>
              {newList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" width={"150px"}>
                    <TypographyCustom>{row.date}</TypographyCustom>
                  </TableCell>
                  <TableCell align="center" width={"200px"}>
                    <TypographyCustom
                      style={{
                        backgroundColor: tabList[row.group].bgColor,
                        borderRadius: 5,
                      }}
                    >
                      {tabList[row.group].name}
                    </TypographyCustom>
                  </TableCell>
                  <TableCell align="left">
                    <TypographyCustom>{row.content}</TypographyCustom>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageBodyContainer>
    </GridLayout>
  );
};

export default HomePage;
