import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  buttonListInfoPage,
  pageInfoInfo,
  topicInfo,
} from "../../PageData/Info/infoPage";
import {
  ButtonCustom,
  GridLayout,
  PageBodyContainer,
  Topic,
} from "../../components";

const Info = () => {
  const nagivate = useNavigate();
  const { state } = useLocation();

  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ block: "center" });
  };

  useEffect(() => {
    if (state?.id) {
      handleClick(state.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBodyContainer pageInfo={pageInfoInfo}>
      <GridLayout
        md={[12]}
        xs={[12]}
        lg={[12]}
        style={{ borderBottom: "1px solid lightgreen" }}
      >
        <>
          {buttonListInfoPage.map((button, index) => {
            return (
              <ButtonCustom
                startIcon={<TurnedInIcon />}
                label={button.name}
                onClick={() =>
                  button.url
                    ? nagivate(button.url)
                    : handleClick(`infoPageTopic${index}`)
                }
              />
            );
          })}
        </>
      </GridLayout>

      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {topicInfo.map((topic, index) => {
          return <Topic topic={topic} id={`infoPageTopic${index}`}></Topic>;
        })}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default Info;
