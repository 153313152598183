import {
  pageInfoPersonalDataSecurity,
  topicPersonalDataSecurity,
} from "../../PageData/PersonalDataSecurity/personalDataSecuirityPage";
import { GridLayout, PageBodyContainer, Topic } from "../../components";
const PersonalDataSecurity = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoPersonalDataSecurity}>
      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {<Topic topic={topicPersonalDataSecurity} id={`ismsTopic`}></Topic>}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default PersonalDataSecurity;
