import { Button } from "@mui/material";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { fontSizeCommon } from "../../style";

const ButtonCustom = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  const {
    startIcon,
    label,
    onClick,
    color = "success",
    variant = "text",
    fontSize = isMobile
      ? fontSizeCommon.mobile.button
      : fontSizeCommon.desktop.button,
    size = "larger",
    style = {},
    disabled = false,
    children,
  } = props;
  return (
    <Button
      variant={variant}
      startIcon={startIcon}
      onClick={onClick}
      //disableRipple={true}
      disabled={disabled}
      size={size}
      color={color}
      style={{
        fontSize: fontSize,
        //position: "unset",
        ...style,
      }}
    >
      {label && label}
      {children && React.Children.map(children, (child, index) => child)}
    </Button>
  );
};

export default ButtonCustom;
