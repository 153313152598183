import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { newList, tabList } from "../../PageData/Hompage/homePage";
import { pageInfoNews } from "../../PageData/News/newPage";
import { PageBodyContainer, Tabs, TypographyCustom } from "../../components";

const News = () => {
  const [setTabIndex] = useState(0);

  return (
    <PageBodyContainer pageInfo={pageInfoNews}>
      <Tabs
        tabList={tabList}
        setSelectTabIndex={setTabIndex}
        style={{ marginTop: "32px", width: "100%" }}
      ></Tabs>
      <TableContainer component={Card} style={{ marginBottom: "32px" }}>
        <Table style={{ width: "max-content", overflow: "scroll" }}>
          <TableBody>
            {newList.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center" width={"150px"}>
                  <TypographyCustom>{row.date}</TypographyCustom>
                </TableCell>
                <TableCell align="center" width={"200px"}>
                  <TypographyCustom
                    style={{
                      backgroundColor: tabList[row.group].bgColor,
                      borderRadius: 5,
                    }}
                  >
                    {tabList[row.group].name}
                  </TypographyCustom>
                </TableCell>
                <TableCell align="left">
                  <TypographyCustom>{row.content}</TypographyCustom>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </PageBodyContainer>
  );
};

export default News;
