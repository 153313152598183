import { routePath } from "../routes/routePath";

export const listMenu = [
  { text: "訪問看護とは", image: "About", routePath: routePath.about },
  { text: "ご利用案内", image: "Info", routePath: routePath.info },
  { text: "お知らせ", image: "News", routePath: routePath.news },
  { text: "交通のご案内", image: "Access", routePath: routePath.access },
  { text: "採用情報", image: "Recruit", routePath: routePath.recruit },
  { text: "お問い合わせ", image: "Contact", routePath: routePath.contact },
];

export const companyInfo = {
  conpanyName: {
    name1: "訪問看護ステーション",
    name2: "グリーンピース",
  },
  phone1: "082-909-2920",
  phone2: "082-909-2920",
};

export const subMenuContent = [
  {
    title: "訪問看護とは",
    content: [
      "・主治医が「訪問看護サービスの利用が必要」と認めた方を対象としたサービスで看護師や理学療法士など、",
      "　主治医の指示に合わせ専門家がご自宅を訪問し療養上のお世話や診療の補助を行います。",
      "　要支援１～要介護５の方または自立支援医療が原因で介護を必要とする方がご利用対象です。",
      "・退院後も自宅での医療管理が必要なとき（栄養剤の点滴が必要など）、自宅での療養生活",
      "　におけるアドバイスがほしいときにご利用ください。",
    ],
    buttonList: [
      { name: "訪問看護とは", link: routePath.about, id: "aboutPageTopic0" },

      {
        name: "経営理念",
        link: routePath.about,
        id: "aboutPageTopic1",
      },
      {
        name: "具体的なサービス内容",
        link: routePath.about,
        id: "aboutPageTopic2",
      },
    ],
  },
  {
    title: "ご利用案内",
    content: [
      "当ステーションでは、ご利用者様、またはそのご家族が安心して生活できるよう、",
      "スタッフが定期的にご自宅に訪問し、相談や必要な支援を行っています。",
    ],
    buttonList: [
      { name: "ご利用までの流れ", link: routePath.info, id: "infoPageTopic0" },
      {
        name: "利用料金",
        link: routePath.info,
        id: "infoPageTopic1",
      },
      {
        name: "事業所案内",
        link: routePath.info,
        id: "infoPageTopic2",
      },
      {
        name: "プライベート看護",
        link: routePath.privacy,
        id: "infoPageTopic3",
      },
    ],
  },
];
