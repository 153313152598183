import EmailIcon from "@mui/icons-material/Email";
import { useMediaQuery } from "react-responsive";
import { pageInfoContact } from "../../PageData/Contact/contactPage";
import { listNav } from "../../PageData/footerInfo";
import {
  ButtonCustom,
  PageBodyContainer,
  TypographyCustom,
} from "../../components";

const Contact = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  const sendMail = () => {
    window.open(
      "mailto:greenpeace0612@gmail.com?subject=件名をご記入下さい&body=本文をご記入下さい"
    );
  };

  return (
    <PageBodyContainer pageInfo={pageInfoContact}>
      <TypographyCustom fontSize={"large"} fontWeight={"bold"} mt={2} mb={4}>
        お問い合わせはこちらまでお願いいたします。{" "}
        {!isMobile && (
          <ButtonCustom
            variant="contained"
            startIcon={<EmailIcon fontSize="large" />}
            label="お問い合わせ"
            onClick={() => sendMail()}
          />
        )}
      </TypographyCustom>

      {listNav.companyInfo.otherInfo.map((info, index) => {
        return <TypographyCustom key={index}>{info}</TypographyCustom>;
      })}
    </PageBodyContainer>
  );
};

export default Contact;
