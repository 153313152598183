import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { pageInfoAccess } from "../../PageData/Access/accessPage";
import {
  PageBodyContainer,
  TableCellCustom,
  TypographyCustom,
} from "../../components";

const Access = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoAccess}>
      <TableContainer
        component={Card}
        style={{ marginTop: "32px", marginBottom: "32px" }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellCustom width={"100px"}>
                <TypographyCustom>住所</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>
                  732-0824 広島県広島市南区的場町2丁目6-3 JH的場301号室
                </TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom>
                <TypographyCustom>TEL</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>082-909-2920</TypographyCustom>
              </TableCellCustom>
            </TableRow>
            <TableRow>
              <TableCellCustom>
                <TypographyCustom>FAX</TypographyCustom>
              </TableCellCustom>
              <TableCellCustom align="left">
                <TypographyCustom>082-554-0833</TypographyCustom>
              </TableCellCustom>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1646.1908217881198!2d132.4745227635748!3d34.391644325203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzTCsDIzJzMwLjciTiAxMzLCsDI4JzMyLjAiRQ!5e0!3m2!1sja!2s!4v1701098773232!5m2!1sja!2s"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="JH的場"
      ></iframe>
    </PageBodyContainer>
  );
};

export default Access;
