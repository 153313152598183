import { Paper } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import GridLayout from "../GridLayout";
import TypographyCustom from "../TypographyCustom";

const Step = (props) => {
  const { info, isEnd, stepNo } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  const style = {
    ballNumber: {
      background: "#cc9933",
      fontSize: isMobile ? "12px" : "20px",
      color: "#FFF",
      fontWeight: "bold",
      width: isMobile ? "32px" : "40px",
      height: isMobile ? "32px" : "40px",
      borderRadius: isMobile ? "16px" : "20px",
      padding: "10px 0px 0px",
      lineHeight: "100%",
      display: "block",
      textAlign: "center",
      marginBottom: isMobile ? "8px" : undefined,
    },
    arrow: {
      height: "calc(100% - 40px) ",
      position: "unset",
      width: "30px",
      display: "flex",
      clipPath:
        "polygon(10px 0px,20px 0px,20px calc(100% - 15px),30px calc(100% - 15px),15px 100%,0px calc(100% - 15px),10px calc(100% - 15px))",
      content: "",
      marginTop: "-2px",
      background: "#cc9933",
    },
  };

  return (
    <>
      <GridLayout
        lg={[1, 11]}
        md={[12]}
        xs={[12]}
        style={{ marginBottom: "16px" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={style.ballNumber}>{stepNo}</div>
          {!isEnd && !isMobile && <div style={style.arrow}></div>}
        </div>
        <Paper
          elevation={8}
          id={`STEP${stepNo}`}
          style={{
            width: "100%",
            height: "fit-content",
            padding: isMobile ? 8 : 32,
            marginTop: 0,
          }}
        >
          <TypographyCustom fontWeight={"bold"} fontSize={isMobile ? 15 : 18}>
            {info.header}
          </TypographyCustom>
          <TypographyCustom fontSize={isMobile ? 15 : 18}>
            {info.content}
          </TypographyCustom>
        </Paper>
      </GridLayout>
    </>
  );
};

export default Step;
