import EmailIcon from "@mui/icons-material/Email";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { Link, Stack } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { listMenu, subMenuContent } from "../../PageData/headerInfo";
import { ButtonCustom, GridLayout, TypographyCustom } from "../../components";
import { commonColor, commonStyle } from "../../style";

import CompanyIntro from "./CompanyIntro";

export const HeaderDesktop = (props) => {
  const { setStickyMode } = props.pageFunc;
  const { stickyMode, currentPage } = props.pageState;
  const location = useLocation();
  const nagivate = useNavigate();
  const [hoverMenu, setHoverMenu] = useState(-1);

  const style = {
    header: {
      position: "fixed",
      top: stickyMode ? -commonStyle.header.height.normal : 0,
      transition: "0.4s",
      background: "#FDFEE2",
      width: "100%",
      zIndex: 3,
    },
    headerMenu: {
      height:
        (stickyMode
          ? commonStyle.header.height.stickyMode
          : commonStyle.header.height.normal) - 20,
      transition: "0.4s",
      borderRight: "0.5px solid #FDFEE2",
      width: "100%",
    },
    subTab: {
      width: "100%",
      //paddingLeft: 290,
      paddingTop: 24,
      paddingBottom: 4,
      backgroundImage: `linear-gradient(to right, #FDFEE2 60%, #9DF05E)`,
      borderBottom: "1px solid lightgrey",
    },
  };

  const handleOnclick = (index, routePath) => {
    if (location.pathname !== routePath) nagivate(routePath);
    setStickyMode(false);
    const element = document.getElementById("top-page");
    element.scrollIntoView({ block: "center" });
  };

  const handleSubTabClick = (link, id) => {
    if (location.pathname === link) {
      const element = document.getElementById(id);
      element.scrollIntoView({ block: "center" });
    } else {
      nagivate(link, {
        state: {
          id,
        },
      });
    }
  };

  const handleOnMouseEnter = (index, event) => {
    setHoverMenu(index);
  };

  const handleOnMouseLeave = () => {
    setHoverMenu(-1);
  };

  const sendMail = () => {
    window.open(
      "mailto:greenpeace0612@gmail.com?subject=件名をご記入下さい&body=本文をご記入下さい"
    );
  };

  const hoverMenuComponent = (hoverMenu) => {
    if (hoverMenu < 0 || hoverMenu >= subMenuContent.length) return null;

    return (
      <div style={style.subTab}>
        <GridLayout lg={[2, 8, 2]}>
          <></>
          <>
            <Stack spacing={2} direction="row" style={{ marginBottom: "12px" }}>
              <TypographyCustom
                style={{
                  fontSize: "30px",
                  borderBottom: "4px solid lightgreen",
                }}
              >
                {subMenuContent[hoverMenu].title}
              </TypographyCustom>
              <ButtonCustom
                variant="contained"
                startIcon={<EmailIcon fontSize="large" />}
                label="お問い合わせ"
                onClick={() => sendMail()}
              />
            </Stack>

            <TypographyCustom>
              {subMenuContent[hoverMenu].content.map((line) => {
                return (
                  <>
                    {line} <br />
                  </>
                );
              })}
            </TypographyCustom>

            <Stack spacing={2} direction="row">
              {subMenuContent[hoverMenu].buttonList.map((button) => {
                return (
                  <ButtonCustom
                    startIcon={<TurnedInIcon />}
                    label={button.name}
                    onClick={() => handleSubTabClick(button.link, button.id)}
                  />
                );
              })}
            </Stack>
          </>
          <></>
        </GridLayout>
      </div>
    );
  };

  return (
    <div id="header" onMouseLeave={handleOnMouseLeave} style={style.header}>
      <CompanyIntro onClick={handleOnclick} />
      <div style={commonStyle.common.spliterLine} />
      <GridLayout md={[2, 8, 2]}>
        <div
          onMouseEnter={(event) => {
            handleOnMouseEnter(-1, event);
          }}
          style={style.headerMenu}
        ></div>
        <GridLayout md={[12 / listMenu?.length]}>
          {listMenu?.map((menu, index) => (
            <Link
              key={index}
              component="button"
              onClick={() => {
                handleOnclick(index, menu.routePath);
              }}
              onMouseEnter={(event) => {
                handleOnMouseEnter(index, event);
              }}
              underline="none"
              width={"100%"}
            >
              <GridLayout md={[12]} style={style.headerMenu}>
                <div
                  style={{
                    height: 5,
                    backgroundColor:
                      currentPage === index || hoverMenu === index
                        ? commonColor.selectedMenuBackground
                        : commonColor.noneSelectMenuBackground,
                  }}
                />
                <TypographyCustom style={commonStyle.header.menuText}>
                  {menu.text}
                </TypographyCustom>
                <div
                  style={{
                    width: stickyMode ? 32 : 64,
                    height: stickyMode ? 32 : 64,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    marginTop: "auto",
                    transition: "0.4s",
                  }}
                >
                  <img
                    src={`./MenuIcon/${menu.image}.png`}
                    style={{
                      maxWidth: stickyMode ? 32 : 64,
                      maxHeight: stickyMode ? 32 : 64,
                      transition: "0.4s",
                    }}
                    alt=""
                  ></img>
                </div>
              </GridLayout>
            </Link>
          ))}
        </GridLayout>
        <></>
      </GridLayout>
      <div style={commonStyle.common.spliterLine} />
      {hoverMenuComponent(hoverMenu)}
    </div>
  );
};

export default HeaderDesktop;
