import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation } from "react-router-dom";
import { routePath } from "../../routes/routePath";
import Footer from "./Footer";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const Layout = (props) => {
  const [currentPage, setCurrentPage] = useState(-1);
  const location = useLocation();

  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });

  const [stickyMode, setStickyMode] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const [contentMinHeight, setContentMinHeight] = useState(undefined);

  useEffect(() => {
    if (stickyMode === false) {
      if (scrollTop > 150) {
        setStickyMode(true);
        //setContentMinHeight("cal(100vh-)")
      }
    } else {
      if (scrollTop <= 150) {
        setStickyMode(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTop]);

  useEffect(() => {
    switch (location.pathname) {
      case routePath.about:
        setCurrentPage(0);
        break;
      case routePath.privacy:
      case routePath.info:
        setCurrentPage(1);
        break;
      case routePath.news:
        setCurrentPage(2);
        break;
      case routePath.access:
        setCurrentPage(3);
        break;
      case routePath.recruit:
        setCurrentPage(4);
        break;
      case routePath.contact:
        setCurrentPage(5);
        break;
      default:
        setCurrentPage(-1);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleScroll = (event) => {
    setScrollTop(document.documentElement.scrollTop || document.body.scrollTop);
  };

  useEffect(() => {
    setContentMinHeight();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <HeaderMobile
          pageFunc={{
            setStickyMode,
            setCurrentPage,
          }}
          pageState={{
            stickyMode,
            currentPage,
          }}
        />
      ) : (
        <HeaderDesktop
          pageFunc={{
            setStickyMode,
            setCurrentPage,
          }}
          pageState={{
            stickyMode,
            currentPage,
          }}
        />
      )}

      <div id="top-page" style={{ marginTop: isMobile ? 65 : 300 }}></div>
      <div
        style={{
          minHeight: contentMinHeight,
          marginTop: isMobile ? undefined : stickyMode ? -50 : 0,
          zIndex: 1,
        }}
      >
        <Outlet />
      </div>
      <Footer
        pageFunc={{
          setStickyMode,
          setCurrentPage,
        }}
        pageState={{
          stickyMode,
          currentPage,
        }}
      />
    </>
  );
};

export default Layout;
