import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";

const GridLayout = (props) => {
  const { children, spacing = 0, xs, md, lg } = props;
  return (
    <Box
      style={{
        ...props.style,
      }}
    >
      <Grid
        container
        rowSpacing={0}
        columnSpacing={0}
        spacing={spacing}
        direction={"row"}
      >
        {React.Children.map(children, (child, index) => (
          <Grid
            key={index}
            item
            xs={xs ? (xs?.length === 1 ? xs[0] : xs[index]) : undefined}
            md={md ? (md?.length === 1 ? md[0] : md[index]) : undefined}
            lg={lg ? (lg?.length === 1 ? lg[0] : lg[index]) : undefined}
            alignItems="center"
            justifyContent="center"
            display={"content"}
          >
            {child}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridLayout;
