import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  buttonListAboutPage,
  pageInfoAbout,
  topicAbout,
} from "../../PageData/About/aboutPage";
import {
  ButtonCustom,
  GridLayout,
  PageBodyContainer,
  Topic,
} from "../../components";

const About = () => {
  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ block: "center" });
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state?.id) {
      handleClick(state.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBodyContainer pageInfo={pageInfoAbout}>
      <GridLayout
        md={[12]}
        lg={[12]}
        xs={[12]}
        style={{
          borderBottom: "1px solid lightgreen",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <>
          {buttonListAboutPage.map((button, index) => {
            return (
              <ButtonCustom
                startIcon={<TurnedInIcon />}
                label={button.name}
                onClick={() => handleClick(`aboutPageTopic${index}`)}
              />
            );
          })}
        </>
      </GridLayout>
      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {topicAbout.map((topic, index) => {
          return <Topic topic={topic} id={`aboutPageTopic${index}`}></Topic>;
        })}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default About;
