const Service = () => {
  return (
    <>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
      <h1>Blog Articles</h1>
    </>
  );
};

export default Service;
