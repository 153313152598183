import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GridLayout,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoLongTermCareInsurance = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
    { name: "利用料金", url: routePath.info, id: "infoPageTopic1" },
    { name: "介護保険", url: routePath.longTermCareInsurance },
  ],
  pageHeader: "介護保険",
};

const style = {
  headerCell: {
    backgroundColor: "#F3EEEA",
  },
};

const table1 = [
  ["訪問看護Ⅰ1", "20分未満", "313単位", "335円", "670円", "1,005円"],
  ["訪問看護Ⅰ2", "30分未満", "470単位", "503円", "1,006円", "1,509円"],
  ["訪問看護Ⅰ3", "30分以上60分未満", "821単位", "878円", "1,757円", "2,635円"],
  [
    "訪問看護Ⅰ4",
    "60分以上90分未満",
    "1,125単位",
    "1,204円",
    "2,408円",
    "3,611円",
  ],
];

const table2 = [
  ["予防訪問看護Ⅰ1", "20分未満", "302単位", "323円", "646円", "969円"],
  ["予防訪問看護Ⅰ2", "30分未満", "450単位", "482円", "963円", "1,445円"],
  [
    "予防訪問看護Ⅰ3",
    "30分以上60分未満",
    "792単位",
    "847円",
    "1,695円",
    "2,542円",
  ],
  [
    "予防訪問看護Ⅰ4",
    "60分以上90分未満",
    "1,087単位",
    "1,163円",
    "2,326円",
    "3,489円",
  ],
];

const table3 = [
  ["初回加算", "", "300単位", "321円", "642円", "963円"],
  ["退院時共同指導加算", "1回につき", "600単位", "642円", "1,284円", "1,926円"],
  ["緊急時訪問看護加算", "1月につき", "574単位", "614円", "1,228円", "1,843円"],
  ["特別管理加算（Ⅰ）", "1月につき", "500単位", "535円", "1,070円", "1,605円"],
  ["特別管理加算（Ⅱ）", "1月につき", "250単位", "268円", "535円", "803円"],
  [
    //{ line1: "複数名訪問加算", line2: "（看護師と看護師等の場合）" },
    <>
      複数名訪問加算
      <br />
      （看護師と看護師等の場合）
    </>,
    "30分未満",
    "254単位",
    "272円",
    "544円",
    "815円",
  ],
  ["30分以上", "402単位", "430円", "860円", "1,290円"],
  [
    //{ line1: "複数名訪問加算", line2: "（看護師と看護補助者の場合）" },
    <>
      複数名訪問加算
      <br />
      （看護師と看護補助者の場合）
    </>,
    "30分未満",
    "201単位",
    "215円",
    "430円",
    "645円",
  ],
  ["30分以上", "317単位", "339円", "678円", "1,018円"],
  [
    "長時間訪問看護加算",
    "90分以上の訪問1回につき",
    "300単位",
    "321円",
    "642円",
    "963円",
  ],
  [
    //{ line1: "ターミナルケア加算", line2: "（介護予防は適用されません）" },
    <>
      ターミナルケア加算
      <br />
      （介護予防は適用されません）
    </>,
    "死亡月につき",
    "2,000単位",
    "2,140円",
    "4,280円",
    "6,420円",
  ],
];

export const topicLongTermCareInsurance = [
  {
    header: "介護保険",
    type: "custom",
    content: (
      <GridLayout md={[12]} xs={[12]}>
        <TypographyCustom>※</TypographyCustom>
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>介護サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>【看護師による訪問看護】</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              {table1.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>
                    *介護保険の給付範囲を超えたサービス利用については、全額自己負担となります
                  </TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>*1単位＝10.7円</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>介護予防サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>【看護師による訪問看護】</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              {table2.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>
                    *介護保険の給付範囲を超えたサービス利用については、全額自己負担となります
                  </TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom align="left" colSpan={6}>
                  <TypographyCustom>*1単位＝10.7円</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHead>
              <TableRow>
                <TableCellCustom
                  width={300}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>サービス内容</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={200}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>備考</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom
                  width={125}
                  style={style.headerCell}
                  rowSpan={2}
                >
                  <TypographyCustom>単位数</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom style={style.headerCell} colSpan={3}>
                  <TypographyCustom>ご利用料金</TypographyCustom>
                </TableCellCustom>
              </TableRow>
              <TableRow>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>1割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>2割負担</TypographyCustom>
                </TableCellCustom>
                <TableCellCustom width={125} style={style.headerCell}>
                  <TypographyCustom>3割負担</TypographyCustom>
                </TableCellCustom>
              </TableRow>
            </TableHead>
            <TableBody>
              {table3.map((row, index) => {
                return index === 5 || index === 7 ? (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom
                          align="left"
                          key={cellIndex}
                          rowSpan={cellIndex === 0 ? 2 : 1}
                        >
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                ) : (
                  <TableRow key={index}>
                    {row.map((cell, cellIndex) => {
                      return (
                        <TableCellCustom align="left" key={cellIndex}>
                          <TypographyCustom>{cell}</TypographyCustom>
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </GridLayout>
    ),
  },
];
