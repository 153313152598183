import { Box } from "@mui/material";

import TypographyCustom from "../TypographyCustom";
import "./style.css";

const Tabs = (props) => {
  const { style } = props;
  const setActiveTab = (evt, index) => {
    console.log(index);
    let tablinks = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    props.setSelectTabIndex(index + 1);
    evt.currentTarget.className += " active";
  };
  return (
    <div className="tab" style={style}>
      <Box>
        {props.tabList?.map((tab, index) => {
          return (
            <TypographyCustom
              key={index}
              className="tablinks"
              onClick={(event) => setActiveTab(event, index)}
            >
              {tab.name}
            </TypographyCustom>
          );
        })}
      </Box>
    </div>
  );
};

export default Tabs;
