import {
  pageInfoLongTermCareInsurance,
  topicLongTermCareInsurance,
} from "../../PageData/Info/infoLongTermCareInsurance";
import { GridLayout, PageBodyContainer, Topic } from "../../components";

const LongTermCareInsurance = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoLongTermCareInsurance}>
      <GridLayout md={[12]} xs={[12]} spacing={6} style={{ marginTop: 32 }}>
        {topicLongTermCareInsurance.map((topic, index) => {
          return (
            <Topic
              topic={topic}
              id={`longTermCareInsurancePageTopic${index}`}
            ></Topic>
          );
        })}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default LongTermCareInsurance;
