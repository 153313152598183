import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { Box, Paper } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import GridLayout from "../GridLayout";
import TypographyCustom from "../TypographyCustom";

const Topic = (props) => {
  const { topic, id, children } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  return (
    <GridLayout md={[12]} xs={[12]} spacing={2}>
      <Box
        id={id}
        style={{
          display: "inline-flex",
          alignItems: "center",
          background: "linear-gradient(to right, #B3DA65, #FDFEE2)",
          width: "100%",
          padding: "4px",
        }}
      >
        <TurnedInIcon color="success"></TurnedInIcon>
        <TypographyCustom
          fontSize={isMobile ? 15 : 20}
          style={{ color: "#2e7d32", marginLeft: 4 }}
        >
          {topic?.header}
        </TypographyCustom>
      </Box>
      <Paper
        elevation={topic.hideBackGround ? 0 : 4}
        style={{
          padding: 32,
          backgroundColor: topic.hideBackGround ? "transparent" : undefined,
        }}
      >
        {topic?.type === "custom" ? (
          topic?.content
        ) : (
          <TypographyCustom fontSize={isMobile ? 15 : undefined}>
            {topic?.content?.map((row, index) => {
              return (
                <>
                  {row} <br />
                </>
              );
            })}
            {children}
          </TypographyCustom>
        )}
      </Paper>
    </GridLayout>
  );
};

export default Topic;
