import { routePath } from "../../routes/routePath";

export const pageInfoPersonalDataSecurity = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "個人情報守秘義務", url: routePath.isms },
  ],
  pageHeader: "個人情報守秘義務",
};

export const topicPersonalDataSecurity = {
  header: "個人情報守秘義務",
  content: [
    "個人情報保護に関する法人の基本姿勢",
    "サービス提供にあたり個人情報を目的以外に利用しないことや苦情処理や相談等適切に取り組むことを宣言するとともに事業所が関係法法令等を厳守し利用目的・公表・開示等個人情報取り扱いを法令に基づき遵守してまいります。",
    "　",
    "利用者及びその家族に関する秘密の保持について",
    "　",
    "①  事業者は、利用者の個人情報について「個人情報の保護に関する法律」及び厚生労働省が策定した「医療・介護関係事業者における個人情報の適切な取り扱いのためのガイドライン」を遵守し、適切な取り扱いに努めるものとします。",
    "②  事業者及び事業者の使用する者（以下「従業者」という。）は、サービス提供をする上で知り得た利用者及びその家族の秘密を正当な理由なく、第三者に漏らしません。",
    "③  また、この秘密を保持する義務は、サービス提供契約が終了した後においても継続します。",
    "④  事業者は、従業者に、業務上知り得た利用者又はその家族の秘密を保持させるため、従業者である期間及び従業者でなくなった後においても、その秘密を保持するべき旨を、従業者との雇用契約の内容とします。",
    "　",
    "個人情報の保護について",
    "　",
    "①  事業者は、利用者から予め文書で同意を得ない限り、サービス担当者会議等において、利用者の個人情報を用いません。また、利用者の家族の個人情報についても、予め文書で同意を得ない限り、サービス担当者会議等で利用者の家族の個人情報を用いません。",
    "②  事業者は、利用者及びその家族に関する個人情報が含まれる記録物（紙によるものの他、電磁的記録を含む。）については、善良な管理者の注意をもって管理し、また処分の際にも第三者への漏洩を防止するものとします。",
    "③  事業者が管理する情報については、利用者の求めに応じてその内容を開示することとし、開示の結果、情報の訂正、追加または削除を求められた場合は、遅滞なく調査を行い、利用目的の達成に必要な範囲内で訂正等を行うものとします。(開示に際して複写料などが必要な場合は利用者の負担となります。)",
  ],
};
