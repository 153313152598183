import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  GridLayout,
  TableCellCustom,
  TypographyCustom,
} from "../../components";
import { routePath } from "../../routes/routePath";

export const pageInfoNursingCareInsurance = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "ご利用案内", url: routePath.info },
    { name: "利用料金", url: routePath.info, id: "infoPageTopic1" },
    { name: "介護保険（定期巡回）", url: routePath.nursingCareInsurance },
  ],
  pageHeader: "介護保険（定期巡回）",
};

const style = {
  headerCell: {
    backgroundColor: "#F3EEEA",
  },
};

const table1 = [
  [
    { rowSpan: 1, colSpan: 1, data: "要介護1" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "2954単位" },
    { rowSpan: 1, colSpan: 1, data: "3,160円" },
    { rowSpan: 1, colSpan: 1, data: "6,320円" },
    { rowSpan: 1, colSpan: 1, data: "9,480円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "要介護2" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "2954単位" },
    { rowSpan: 1, colSpan: 1, data: "3,160円" },
    { rowSpan: 1, colSpan: 1, data: "6,320円" },
    { rowSpan: 1, colSpan: 1, data: "9,480円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "要介護3" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "2954単位" },
    { rowSpan: 1, colSpan: 1, data: "3,160円" },
    { rowSpan: 1, colSpan: 1, data: "6,320円" },
    { rowSpan: 1, colSpan: 1, data: "9,480円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "要介護4" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "2954単位" },
    { rowSpan: 1, colSpan: 1, data: "3,160円" },
    { rowSpan: 1, colSpan: 1, data: "6,320円" },
    { rowSpan: 1, colSpan: 1, data: "9,480円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "要介護5" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "3754単位" },
    { rowSpan: 1, colSpan: 1, data: "4,016円" },
    { rowSpan: 1, colSpan: 1, data: "8,032円" },
    { rowSpan: 1, colSpan: 1, data: "12,048円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "初期加算" },
    { rowSpan: 1, colSpan: 1, data: "1日につき" },
    { rowSpan: 1, colSpan: 1, data: "30単位" },
    { rowSpan: 1, colSpan: 1, data: "32円" },
    { rowSpan: 1, colSpan: 1, data: "64円" },
    { rowSpan: 1, colSpan: 1, data: "96円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "退院時共同指導加算" },
    { rowSpan: 1, colSpan: 1, data: "1回につき" },
    { rowSpan: 1, colSpan: 1, data: "600単位" },
    { rowSpan: 1, colSpan: 1, data: "642円" },
    { rowSpan: 1, colSpan: 1, data: "1,284円" },
    { rowSpan: 1, colSpan: 1, data: "1,926円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "緊急時訪問看護加算" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "574単位" },
    { rowSpan: 1, colSpan: 1, data: "614円" },
    { rowSpan: 1, colSpan: 1, data: "1,228円" },
    { rowSpan: 1, colSpan: 1, data: "1,843円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "特別管理加算（Ⅰ）" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "500単位" },
    { rowSpan: 1, colSpan: 1, data: "535円" },
    { rowSpan: 1, colSpan: 1, data: "1,070円" },
    { rowSpan: 1, colSpan: 1, data: "1,605円" },
  ],
  [
    { rowSpan: 1, colSpan: 1, data: "特別管理加算（Ⅱ）" },
    { rowSpan: 1, colSpan: 1, data: "1月につき" },
    { rowSpan: 1, colSpan: 1, data: "250単位" },
    { rowSpan: 1, colSpan: 1, data: "268円" },
    { rowSpan: 1, colSpan: 1, data: "535円" },
    { rowSpan: 1, colSpan: 1, data: "803円" },
  ],
];

const TableHeadNursingCareInsurance = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCellCustom width={175} style={style.headerCell} rowSpan={2}>
          <TypographyCustom>サービス内容</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} rowSpan={2} style={style.headerCell}>
          <TypographyCustom>備考</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} rowSpan={2} style={style.headerCell}>
          <TypographyCustom>単位数</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell} colSpan={3}>
          <TypographyCustom>ご利用料金</TypographyCustom>
        </TableCellCustom>
      </TableRow>
      <TableRow>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>1割負担</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>2割負担</TypographyCustom>
        </TableCellCustom>
        <TableCellCustom width={125} style={style.headerCell}>
          <TypographyCustom>3割負担</TypographyCustom>
        </TableCellCustom>
      </TableRow>
    </TableHead>
  );
};

const TableBodyNursingCareInsurance = (props) => {
  return (
    <TableBody>
      {props.rowData.map((row, index) => {
        return (
          <TableRow key={index}>
            {row.map((cell, cellIndex) => {
              if (cell.data === "") return null;
              return (
                <TableCellCustom
                  align={
                    cellIndex < props.align.length
                      ? props.align[cellIndex]
                      : "right"
                  }
                  key={cellIndex}
                  // width={
                  //   cellIndex < props.width.length
                  //     ? props.width[cellIndex]
                  //     : undefined
                  // }
                  rowSpan={cell.rowSpan}
                  colSpan={cell.colSpan}
                >
                  <TypographyCustom>{cell.data}</TypographyCustom>
                </TableCellCustom>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export const topicNursingCareInsurance = [
  {
    header: "介護保険（定期巡回）",
    type: "custom",
    content: (
      <GridLayout md={[12]} xs={[12]}>
        <TypographyCustom>
          定期巡回・随時対応型訪問看護連携料金表
        </TypographyCustom>
        <TableContainer style={{ marginBottom: "32px" }}>
          <Table style={{ width: "900px", overflow: "scroll" }}>
            <TableHeadNursingCareInsurance />
            <TableBodyNursingCareInsurance
              rowData={table1}
              align={["left", "left"]}
            />
          </Table>
        </TableContainer>
      </GridLayout>
    ),
  },
];
