import {
  pageInfoOperating,
  topicOperating,
} from "../../PageData/Operating/operatingPage";
import { GridLayout, PageBodyContainer, Topic } from "../../components";
const Operating = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoOperating}>
      <GridLayout md={[12]} spacing={6} style={{ marginTop: 32 }}>
        {<Topic topic={topicOperating} id={`ismsTopic`}></Topic>}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default Operating;
