export const routePath = {
  homepage: "/",
  about: "/about",
  info: "/info",
  news: "/news",
  access: "/access",
  recruit: "/recruit",
  contact: "/contact",
  service: "/service",
  company: "/company",
  place: "/place",
  isms: "/isms",
  operating: "/operating",
  privacy: "/privacy",
  link: "/link",
  longTermCareInsurance: "/longTermCareInsurance",
  medicalInsurance: "/medicalInsurance",
  nursingCareInsurance: "/nursingCareInsurance",
};
