import { pageInfoLink } from "../../PageData/LinkPage/linkPage";
import { PageBodyContainer, TypographyCustom } from "../../components";

const LinkPage = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoLink}>
      <TypographyCustom fontSize={"large"} fontWeight={"bold"} mt={2} mb={4}>
        現在、他サイトへのリンクのご案内はありません。
      </TypographyCustom>
    </PageBodyContainer>
  );
};

export default LinkPage;
