import { TableCell } from "@mui/material";

const TableCellCustom = (props) => {
  const { style, align = "center", children, ...restProps } = props;
  return (
    <TableCell
      align={align}
      style={{ border: "1px solid lightgray", ...style }}
      {...restProps}
    >
      {children}
    </TableCell>
  );
};

export default TableCellCustom;
