import { Link } from "@mui/material";
import { companyInfo } from "../../PageData/headerInfo";
import { TypographyCustom } from "../../components";
import { routePath } from "../../routes/routePath";

const CompanyIntro = (props) => {
  const style = {
    companyName: {
      color: "#9DF05E",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "28px",
      transition: "0.4s",
    },
    title: {
      fontSize: 16,
      textAlign: "left",
      display: "inline-block",
      verticalAlign: "middle",
      padding: 4,
      paddingRight: 8,
      paddingLeft: 8,
      borderRadius: 5,
    },
    info: {
      fontSize: 27,
      marginLeft: 8,
      textAlign: "left",
      display: "inline-block",
      verticalAlign: "middle",
    },
  };
  return (
    <div
      style={{
        height: props.isMobile ? 100 : 150,
        width: "100%",
        display: "inline-flex",
      }}
    >
      <img
        style={{
          height: props.isMobile ? 80 : 130,
          width: props.isMobile ? 80 : 156,
          backgroundRepeat: "no-repeat",
          margin: 10,
        }}
        src={"./profile.png"}
        alt="header"
        loading="eager 0.4s"
      ></img>
      <Link
        component="button"
        onClick={() => {
          props.onClick(-1, routePath.homepage);
        }}
        underline="none"
        style={style.headerMenu}
      >
        <div
          style={{
            marginTop: 15,
            marginLeft: 20,
            width: "max-content",
          }}
        >
          <TypographyCustom style={style.companyName}>
            {companyInfo.conpanyName.name1}
          </TypographyCustom>
          <TypographyCustom style={{ ...style.companyName, fontSize: 40 }}>
            {companyInfo.conpanyName.name2}
          </TypographyCustom>
        </div>
      </Link>
      <div
        style={{
          marginTop: 20,
          marginRight: 10,
          width: "100%",
        }}
      >
        <div
          style={{
            marginLeft: "auto",
            width: "max-content",
            height: "60px",
            lineHeight: "60px",
          }}
        >
          <TypographyCustom
            style={{
              ...style.title,
              backgroundColor: "lightGrey",
            }}
          >
            営業時間
          </TypographyCustom>
          <TypographyCustom style={style.info}>9:00～18:00</TypographyCustom>

          <TypographyCustom
            style={{
              ...style.title,
              backgroundColor: "lightGrey",
              marginLeft: 32,
            }}
          >
            住所
          </TypographyCustom>
          <TypographyCustom style={{ ...style.info, fontSize: 16 }}>
            〒732-0824
            <br />
            広島県広島市南区的場町2丁目6-3　JH的場301号室
          </TypographyCustom>
          <br />
          <TypographyCustom
            style={{
              ...style.title,
              backgroundColor: "#9DF05E",
            }}
          >
            TEL
          </TypographyCustom>
          <TypographyCustom style={{ ...style.info, fontSize: 30 }}>
            082-909-2920
          </TypographyCustom>

          <TypographyCustom
            style={{
              ...style.title,
              backgroundColor: "#9DF05E",
              marginLeft: 26,
            }}
          >
            FAX
          </TypographyCustom>
          <TypographyCustom style={{ ...style.info, fontSize: 30 }}>
            082-554-0833
          </TypographyCustom>
        </div>
      </div>
    </div>
  );
};

export default CompanyIntro;
