import { Link } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { GridLayout, TypographyCustom } from "..";

const PageBodyContainer = (props) => {
  const { children, style, pageInfo } = props;
  const [minHeight, setMinheight] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 1200px)` });
  useEffect(() => {
    const root = document.getElementById("root").clientHeight;
    const minHeight = window.innerHeight - root - 299;
    if (minHeight > 0) setMinheight(minHeight);
  }, []);
  const nagivate = useNavigate();
  return (
    <Fragment>
      <GridLayout xs={[12]} md={[12]} style={{ ...style }}>
        {pageInfo && (
          <div
            style={{
              //backgroundImage: `url(${pageInfo?.headerImage})`,
              //backgroundRepeat: "no-repeat",
              width: "100%",
              //height: isMobile ? "100px" : undefined,
              backgroundSize: "100% 100%",
              backgroundColor: "#9DF05E",
            }}
          >
            <TypographyCustom
              fontSize={isMobile ? 20 : 30}
              fontWeight={"bold"}
              color={"darkgreen"}
              style={{
                width: "100%",
                height: isMobile ? "50px" : "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {pageInfo?.pageHeader}
            </TypographyCustom>
          </div>
        )}
        <GridLayout md={[2, 8, 2]} xs={[0.5, 11, 0.5]}>
          <></>
          <div>
            {pageInfo && (
              <>
                {pageInfo.root?.map((link, index) => {
                  return (
                    <>
                      {index !== 0 && " ＞ "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() =>
                          link.id
                            ? nagivate(link.url, {
                                state: { id: link.id },
                              })
                            : nagivate(link.url)
                        }
                        width="fit-content"
                        height="fit-content"
                        fontSize={isMobile ? undefined : 20}
                      >
                        {link.name}
                      </Link>
                    </>
                  );
                })}
              </>
            )}
            {React.Children.map(children, (child) => child)}
            <div style={{ height: minHeight, marginBottom: "16px" }}></div>
          </div>
          <></>
        </GridLayout>
      </GridLayout>
    </Fragment>
  );
};

export default PageBodyContainer;
