import { GridLayout, TypographyCustom } from "../../components";
import { routePath } from "../../routes/routePath";
import { commonStyle } from "../../style";

export const pageInfoAbout = {
  root: [
    { name: "ホームページ", url: routePath.homepage },
    { name: "訪問看護とは", url: routePath.about },
  ],
  pageHeader: "訪問看護とは",
};

export const buttonListAboutPage = [
  { name: "訪問看護とは" },

  {
    name: "経営理念",
  },
  {
    name: "具体的なサービス内容",
  },
];

export const topicAbout = [
  {
    header: "訪問看護とは",
    content: [
      "・主治医が「訪問看護サービスの利用が必要」と認めた方を対象としたサービスで看護師や理学療法士など、",
      "　主治医の指示に合わせ専門家がご自宅を訪問し療養上のお世話や診療の補助を行います。",
      "　要支援１～要介護５の方または自立支援医療が原因で介護を必要とする方がご利用対象です。",
      "・退院後も自宅での医療管理が必要なとき（栄養剤の点滴が必要など）、自宅での療養生活におけるアドバイスがほしいときにご利用ください。",
    ],
  },

  {
    header: "経営理念",
    type: "custom",
    content: (
      <GridLayout md={[2, 8, 2]} xs={[0, 12, 0]}>
        <></>
        <div style={commonStyle.common.centerMiddleBox}>
          <TypographyCustom
            width={"100%"}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={"40px"}
            fontFamily={"UD Digi Kyokasho NK-B"}
          >
            理念
          </TypographyCustom>
          <TypographyCustom
            width={"100%"}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={"30px"}
            fontFamily={"UD Digi Kyokasho NK-B"}
          >
            【誠心誠意】
            <br />
            住み慣れたご自宅や施設で安心して療養生活が送れる
            <br />
            よう心を込めて看護します
          </TypographyCustom>
          <TypographyCustom
            width={"100%"}
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={"40px"}
            fontFamily={"UD Digi Kyokasho NK-B"}
            paddingTop={8}
          >
            基本方針
          </TypographyCustom>
          <TypographyCustom
            width={"100%"}
            textAlign={"left"}
            fontWeight={"bold"}
            fontSize={"30px"}
            fontFamily={"UD Digi Kyokasho NK-B"}
          >
            療養者が可能な限り自立し、安心して日常生活を送れるよう、
            <br />
            地域の医療機関、主治医、各事業所との連携を密にし、
            <br />
            療養者の在宅療養に必用なネットワークサービスが提供できるよう支援します。
            <br />
            <br />
            地域で必要とされ、無くてはならない事業所を目指し、
            <br />
            全職員の成長と夢を実現する事業所にします。
          </TypographyCustom>
        </div>
        <></>
      </GridLayout>
    ),
  },
  {
    header: "具体的なサービス内容",
    content: [
      "・健康状態の管理（バイタルチェック（血圧、体温、脈拍などのチェック）、病状の観察、精神面のケア）",
      "・自宅でのリハビリテーション（関節の硬化を防ぐ運動、日常生活動作の訓練（歩行、排泄など））",
      "・治療促進のための看護（医療機器や器具の管理、服薬指導、主治医の指示による処置や検査）",
      "・相談（住宅改修や福祉用具導入に関する相談、介護負担に関する相談、健康管理、日常生活に関する相談）",
      "・終末期の看護（痛みの緩和、本人や家族の精神的な支援、看取りの体制に関する相談）",
    ],
  },
];
