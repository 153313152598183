import { Router } from "./routes/route";

export default function App() {
  if (window.location.host === "greenpeace-kango-2024.firebaseapp.com")
    return 404;

  if (window.location.host === "greenpeace-kango-2024.web.app") return 404;

  return (
    <Router></Router>
  );
}
