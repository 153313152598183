import {
  pageInfoMedicalInsurance,
  topicMedicalInsurance,
} from "../../PageData/Info/infoMedicalInsurance";
import { GridLayout, PageBodyContainer, Topic } from "../../components";

const MedicalInsurance = () => {
  return (
    <PageBodyContainer pageInfo={pageInfoMedicalInsurance}>
      <GridLayout md={[12]} xs={[12]} spacing={6} style={{ marginTop: 32 }}>
        {topicMedicalInsurance.map((topic, index) => {
          return (
            <Topic
              topic={topic}
              id={`longTermCareInsurancePageTopic${index}`}
            ></Topic>
          );
        })}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default MedicalInsurance;
