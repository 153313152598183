import TurnedInIcon from "@mui/icons-material/TurnedIn";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  buttonListPrivacyPage,
  pageInfoPrivacy,
  topicPrivacy,
} from "../../PageData/Privacy/privacyPage";
import {
  ButtonCustom,
  GridLayout,
  PageBodyContainer,
  Topic,
} from "../../components";
const Privacy = () => {
  const nagivate = useNavigate();
  const { state } = useLocation();

  const handleClick = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ block: "center" });
  };

  useEffect(() => {
    if (state?.id) {
      handleClick(state.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBodyContainer pageInfo={pageInfoPrivacy}>
      <GridLayout
        md={[12]}
        lg={[12]}
        xs={[12]}
        style={{ borderBottom: "1px solid lightgreen" }}
      >
        <>
          {buttonListPrivacyPage.map((button, index) => {
            return (
              <ButtonCustom
                startIcon={<TurnedInIcon />}
                label={button.name}
                onClick={() =>
                  button.url
                    ? nagivate(button.url, {
                        state: { id: `infoPageTopic${index}` },
                      })
                    : handleClick(`infoPageTopic${index}`)
                }
              />
            );
          })}
        </>
      </GridLayout>
      <GridLayout md={[12]} xs={[12]} spacing={6} style={{ marginTop: 32 }}>
        {<Topic topic={topicPrivacy} id={`infoPageTopic3`}></Topic>}
      </GridLayout>
    </PageBodyContainer>
  );
};

export default Privacy;
