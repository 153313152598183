export const commonColor = {
  selectedMenuBackground: "#618264",
  noneSelectMenuBackground: "#B0D9B1",
};
export const fontSizeCommon = {
  desktop: {
    button: "20px",
  },
  mobile: {
    button: "15px",
  },
};
export const commonStyle = {
  common: {
    centerMiddleBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    spliterLine: {
      backgroundColor: "#9DF05E",
      height: "10px",
    },
  },
  header: {
    height: {
      normal: 150,
      stickyMode: 100,
    },
    menuText: {
      marginTop: "8px",
      color: "black",
      fontWeight: "bolder",
      fontSize: "20px",
    },
  },
  body: {
    pageHeader: {
      borderBottom: "1px solid lightgray",
      marginBottom: 32,
      marginTop: 8,
      fontSize: "30px",
      fontWeight: "bold",
    },
    paperHeader: {
      borderBottom: "1px solid lightgray",
      marginBottom: 16,
      fontSize: "larger",
    },
    paperContent: {
      fontSize: "large",
    },
  },
  footer: {
    height: 206,
    title: {
      background: "linear-gradient(to right, #B3DA65, #FDFEE2)",
      marginTop: 16,
      marginRight: 16,
      paddingLeft: 16,
    },
    item: {},
    itemLink: {
      color: "black",
    },
  },
};
