import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  About,
  Access,
  Company,
  Contact,
  HomePage,
  Info,
  Layout,
  LinkPage,
  LongTermCareInsurance,
  MedicalInsurance,
  News,
  NursingCareInsurance,
  Operating,
  PersonalDataSecurity,
  Place,
  Privacy,
  Recruit,
  Service,
} from "../pages";
import { routePath } from "./routePath";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path={routePath.about} element={<About />} />
          <Route path={routePath.info} element={<Info />} />
          <Route path={routePath.news} element={<News />} />

          <Route path={routePath.access} element={<Access />} />
          <Route path={routePath.recruit} element={<Recruit />} />
          <Route path={routePath.contact} element={<Contact />} />

          <Route path={routePath.service} element={<Service />} />
          <Route path={routePath.company} element={<Company />} />
          <Route path={routePath.place} element={<Place />} />
          <Route path={routePath.contact} element={<Contact />} />
          <Route path={routePath.privacy} element={<Privacy />} />
          <Route path={routePath.isms} element={<PersonalDataSecurity />} />
          <Route path={routePath.operating} element={<Operating />} />
          <Route path={routePath.link} element={<LinkPage />} />
          <Route
            path={routePath.longTermCareInsurance}
            element={<LongTermCareInsurance />}
          />
          <Route
            path={routePath.medicalInsurance}
            element={<MedicalInsurance />}
          />
          <Route
            path={routePath.nursingCareInsurance}
            element={<NursingCareInsurance />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
